


export const TestimonialApi = [
    {
        id: 1,
        client: 'Samad Khan',
        describe: 'Simplex Team helped us to get our Canada PR Visa within Eleven months. I can recommend Simplex Global to anyone as they have a highly talented and professional staff. Special thanks go to Shristi who was very responsive and helpful throughout the process.',
    },
    {
        id: 2,
        client: 'Amit Kumar',
        describe: 'Vineet was very accommodating and assisted me in my Germany Visa Application which I started in 2021 but for some reason, my appointment was paid but not scheduled. He explained the process on the visa application process and sat through it and guided me step by step.  I highly recommend Simplex Global to anyone.',
    },
    {
        id: 3,
        client: 'Vineeta Jain',
        describe: 'I was really impressed by the level of service I received from the Simplex Immigration Team. They were always available to answer my questions and provide me with the necessary information about my Canada pr visa. They made the process of applying for permanent residence so much easier for me and my family.',
    },
    {
        id: 4,
        client: 'Hamid ',
        describe: 'I applied for a work permit for Poland unskilled recruitment through Simplex Global Immigration and I got an offer from a Truck Driving agency there. The process was completed in 2 months and it was smooth Highly recommend it!',
    },
    {
        id: 5,
        client: 'Neeraj Chauhan',
        describe: 'I am so grateful for the support and assistance provided by the Simplex immigration team during my Canada PR visa application process. Their expertise and attention to detail helped me to successfully achieve my goal of becoming a permanent resident in Canada.',
    },
    {
        id: 6,
        client: 'Akash Gupta',
        describe: 'From the first meeting with Vineet, we were treated like friends, everything was explained based on our background for our Germany Job Seeker visa application. Though our application was very unique, Vineet & his teams made the whole process stress-free, with their timely communication and vast knowledge/experience.',
    },
    {
        id: 7,
        client: 'Honey Ahuja',
        describe: 'The Simplex Immigration team was instrumental in helping me to navigate the complex Germany Job Seeker visa application process. Their personalized approach and constant support made the process much less stressful. I was able to get my visa approved quickly and I was able to find a job in Germany in a short time, thanks to their help.',
    },
    {
        id: 8,
        client: 'Sunny Hinduja',
        describe: 'Gotten a Canada Pr visa with Simplex Teams help. I just needed to provide the necessary documents and Simplex Team will assist with the rest of the application. They have made the process easy for me and the fees are affordable as well. Thank you and special thanks to Daphnie who responds my queries regardless of the time.',
    }
]